import React, { useState, useEffect } from 'react';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faEdit, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import fetchMethodRequest from '../../../config/service';
import apiCalls from '../../../config/apiCalls';
import Icon from "mdi-react/ArrowLeftIcon";
import { useNavigate } from 'react-router';
import { Card } from 'primereact/card';
import { CardBody } from 'reactstrap';
import DeleteRowModal from '../CommonModals/DeleteRowModal';
import DoAutoCompleteField from '../../Form/Fields/DoAutoCompleteField';
import DoSelectField from '../../Form/Fields/DoSelectField';
import DoInputField from '../../Form/Fields/DoInputField';
import { useForm, Controller } from 'react-hook-form';
import { Dropdown } from 'primereact/dropdown';
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from 'yup';
import showToasterMessage from '../../UI/ToasterMessage/toasterMessage';
const options = require('../../../translations/states/options.json')
const validationSchema = Yup.object().shape({
    // productName: Yup.object().required('Product Name is required'),
    required: Yup.string().required('Required field is required'),
    storageUnitType: Yup.string().required('Select Location Type is required'),
});

export default function RecommendedInventoriesDataTable(props) {
    const {
        control,
        handleSubmit,
        formState: { errors },
        setValue,
        getValues,
        reset,
    } = useForm({
        resolver: yupResolver(validationSchema),
        defaultValues: {},
    });

    const [products, setProducts] = useState([]);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [showAddModal, setShowAddModal] = useState(false);
    const [minInventoryByStateName, setName] = useState('');
    const [nameError, setNameError] = useState('');
    const [showDeleteRowModal, setShowDeleteRowModal] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [editingIndex, setEditingIndex] = useState(null);
    const [productDescriptions, setProductDescriptions] = useState({});
    const [selectedProductName, setSelectedProductName] = useState('');
    const [rowDataId, setRowDataId] = useState(JSON.parse(localStorage.getItem('ProductRowData')) || null);
    const [categoryType, setCatagoryType] = useState('')
    const [stateName, setSelectedState] = useState(null);
    const [loginRole, setloginRole] = useState()
    const [productId, setProductID] = useState('')
    const [id, setID] = useState('')
    const [orderRequired, setOrderRequired] = useState('')
    const navigate = useNavigate();

    // const [stateName, setStateName] = useState(localStorage.getItem('minInventoryByStateName') || '');

    /**
     * Table Header Fields
     */
    const columns = [
        { field: 'sn', header: 'S.No' },
        { field: 'productName', header: 'Product Name' },
        { field: 'description', header: 'Description' },
        { field: 'volume', header: 'Volume' },
        { field: 'required', header: 'Required' },
        { field: 'storageUnitType', header: 'storage Type' },
        { field: 'categoryName', header: 'Category Name' },
        { field: 'categoryType', header: 'Category Type' },
        { field: 'actions', header: 'Actions' }
    ];


    /**
    * form Fields
    */
    const formFields = [
        {
            field: 'productName',
            label: 'Product Name',
            type: 'autocomplete',
            searchApi: 'products',
            searchField: 'title1',
            placeholder: 'Search'
        },
        {
            field: 'description',
            label: 'Description',
            type: 'text',
            placeholder: 'Description',
            disable: true
        },
        {
            field: 'volume',
            label: 'Volume',
            type: 'text',
            placeholder: 'Volume',
            disable: true
        },
        {
            field: 'categoryName',
            label: 'Category Name',
            type: 'text',
            placeholder: 'Category Name',
            disable: true
        },
        {
            field: 'inventoryType',
            label: 'Select Inventory Type',
            type: 'text',
            placeholder: 'Select Inventory Type',
            disable: true
        },
        {
            field: 'storageUnitType',
            label: 'Select Location Type',
            type: 'dropDown',
            placeholder: 'Location Type',
            options: [
                { label: 'Cart Location', value: 'cartLocation' },
                { label: 'Supply Cabinet', value: 'supplyCabinet' },
                { label: 'OR Suite', value: 'orSuit' }
            ]
        },
        {
            field: 'required',
            label: 'Required',
            type: 'text',
            placeholder: 'Required'
        },
    ];

    useEffect(() => {
        const loginCredentials = localStorage.getItem("loginCredentials");
        const credentialsObject = JSON.parse(loginCredentials);
        setloginRole(credentialsObject.role)
        let storedProducts = JSON.parse(localStorage.getItem('products')) || [];
        const editData = JSON.parse(localStorage.getItem('ProductRowData')) || {};
        if (editData && editData.minInventoryByStates) {
            storedProducts = editData.minInventoryByStates;
            setName(editData.minInventoryByStateName)
            setSelectedState(editData.stateName)
        }
        // minInventry by state Name
        // else if(stateName !== "undefined" && editData && Object.keys(editData).length === 0) {
        //     setName(stateName);
        // }
        // const hasSerialNumbers = storedProducts.every(product => typeof product.sn === 'number');

        let productsWithSerialNumbers = storedProducts;
        productsWithSerialNumbers = storedProducts.map((product, index) => ({
            ...product,
            sn: index + 1
        }));
        localStorage.setItem('products', JSON.stringify(productsWithSerialNumbers));

        setProducts(productsWithSerialNumbers);
    }, []);

    /**
        * Opening Add Modal
        */
    const handleAddButtonClick = () => {
        setShowAddModal(true);
        if (isEditing && editingIndex !== null) {
            setSelectedProduct(products[editingIndex]);
        } else {
            setSelectedProduct({
                // id: newProductId,
                productName: '',
                description: '',
                volume: '',
                required: '',
                storageUnitType: '',
                categoryType: '',
                categoryName: '',
                selectInventoryType: '',
            });
        }
    };


    /**
    * Close Add Modal
    */
    const hideAddModal = () => {
        setShowAddModal(false);
        setSelectedProduct(null);
        reset();
        setIsEditing(false);
        setEditingIndex(null);
    };

    const onSubmit = (data, rowData) => {
        if (data) {
            data = {
                productName: data.productName.productName ? data.productName.productName : data.productName,
                categoryType: data.productName.categoryType ? data.productName.categoryType : categoryType,
                categoryName: data.categoryName,
                volume: data.volume,
                inventoryType: data.inventoryType,
                required: data.required,
                storageUnitType: data.storageUnitType,
                description: data.description,
                productId: data.productName._id ? data.productName._id : productId,
                _id: data.productName._id ? data.productName._id : id,
                orderRequired: data.productName.orderRequired || data.productName.orderRequired == 0 ? data.productName.orderRequired : orderRequired
            }
        }
        const updatedProducts = [...products];
        if (isEditing && editingIndex !== null) {
            updatedProducts[editingIndex] = {
                ...updatedProducts[editingIndex],
                ...data,
            };
        } else {
            updatedProducts.push({
                ...data,
                sn: updatedProducts.length + 1,
            });
        }

        setProducts(updatedProducts);
        localStorage.setItem('products', JSON.stringify(updatedProducts));
        setNameError('');
        setIsEditing(false);
        setEditingIndex(null);
        hideAddModal();
    };





    /**
    * Handle Edit Functionality
    */
    const handleEdit = (rowData, index, type) => {
        console.log("rowData>>>>>>>>>>>>>>>>>>>>>>>", rowData)
        // Extract categoryType from rowData
        setProductID(rowData.productId)
        setID(rowData._id)
        setOrderRequired(rowData.orderRequired)
        setCatagoryType(rowData.categoryType)
        setSelectedProduct(rowData);
        setIsEditing(true);
        setEditingIndex(index);
        setShowAddModal(true);
        setTimeout(() => {
            formFields.forEach((item) => {
                setValue(item.field, rowData[item.field]);
            });
        }, 0);
    };

    const handleDeleteRowConfirmation = () => {
        const updatedProducts = products.filter((product) => product !== selectedProduct);
        const productsWithSerialNumbers = updatedProducts.map((product, index) => ({
            ...product,
            sn: index + 1
        }));
        setProducts(productsWithSerialNumbers);
        localStorage.setItem('products', JSON.stringify(productsWithSerialNumbers));
        localStorage.setItem('ProductRowData', JSON.stringify(productsWithSerialNumbers));
        setShowDeleteRowModal(false);
        setSelectedProduct(null);
    };



    const handleProductChanges = (value) => {
        if (value.productName) {
            setValue('description', value.description);
            setValue('volume', value.volume);
            setValue('categoryName', value.categoryName);
            setValue('inventoryType', value.inventoryType);
        }
    }


    const handleApiData = () => {
        const loginCredentials = JSON.parse(localStorage.getItem('loginCredentials'));
        const { companyName, state, _id } = loginCredentials;
        if (!minInventoryByStateName.trim()) {
            setNameError('Name is required');
            return;
        }
        const payload = {
            minInventoryByStateName,
            state,
            stateName,
            minInventoryByStates: [...products],
            companyName,
            companyId: _id
        };

        let apiUrl;
        let method;
        if (rowDataId && rowDataId._id) {
            apiUrl = `${apiCalls.recommendedInventories}/${rowDataId._id}`
            method = 'PUT'
        } else {
            apiUrl = `${apiCalls.recommendedInventories}`;
            method = 'POST'
        }

        return fetchMethodRequest(method, apiUrl, payload)
            .then((response) => {
                if (response && response.respMessage) {
                    showToasterMessage(response.respMessage, 'success');

                    setNameError('');
                    setName('');
                    setProducts([]);
                    navigate('/recommendedInventories');
                } else {
                    showToasterMessage(response.errorMessage, 'error');

                }
            });
    };

    const getlogincredentials = () => {
        const loginCredentials = localStorage.getItem("loginCredentials");
        const credentialsObject = JSON.parse(loginCredentials);
        let loginRole = credentialsObject.role
        const companyId = credentialsObject._id;
        userBody.loginRole = `${loginRole}`;
        userBody.companyId = loginRole === 'Admin' ? `${companyId}` : loginRole === 'Super Admin' ? `${props.adminCompanyId}` : null;
    }


    return (
        <Card className='cardForListMargin'>
            <CardBody className='tableCardBody'>
                <div className="card">
                    <div style={{ display: 'flex' }}>
                        <h4 style={{ color: '#3475aa' }}>Recommended Inventories</h4>
                    </div>
                    <div style={{ marginTop: '1rem', display: 'flex' }}>
                        <div className='col-3'>
                            <label style={{ fontSize: '16px', marginLeft: '10px' }}>Name<span style={{ color: 'red' }}>*</span></label>&nbsp;
                            <InputText
                                required
                                value={minInventoryByStateName}
                                style={{ width: '70%' }}
                                onChange={(e) => {
                                    setName(e.target.value);
                                    if (nameError) {
                                        setNameError('');
                                    }
                                }}
                            />
                            <div>
                                {nameError && <small style={{ color: 'red', marginLeft: '10px' }}>{nameError}</small>}
                            </div>
                        </div>

                        <div className='col-3'>
                            {loginRole !== 'Admin' &&
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <label style={{ fontSize: '16px', marginLeft: '10px' }}>State Name<span style={{ color: 'red' }}>*</span></label>&nbsp;
                                    <Dropdown
                                        id="states"
                                        name="states"
                                        style={{ width: '70%' }}
                                        value={stateName}
                                        options={options}
                                        onChange={(e) => setSelectedState(e.value)}
                                        placeholder="Select State"
                                    />
                                </div>
                            }
                        </div>

                        <div className='col-6' style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <Button onClick={handleAddButtonClick} className="btn custom-outline">Add</Button>&nbsp;&nbsp;
                            <Button onClick={handleApiData} className="btn custom-outline" outlined disabled={products.length === 0}>Submit</Button>
                        </div>
                    </div>

                    <div style={{ marginTop: '1rem' }}>
                        <table className="table table-bordered text-center">
                            <thead className='table_bg_color'>
                                <tr>
                                    {columns.map((column, index) => (
                                        <th className="text-white table_header_data" key={index}>{column.header}</th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {products.length === 0 ? (
                                    <tr>
                                        <td colSpan="9" className="text-center">No data found</td>
                                    </tr>
                                ) : (
                                    products.map((product, index) => (
                                        <tr key={index}>
                                            <td className='text-center'>{product.sn}</td>
                                            <td className='table_data'>{product.productName ? product.productName : ''}</td>
                                            <td className='text-center'>{product.description}</td>
                                            <td className='text-center'>{product.volume ? product.volume : '-'}</td>
                                            <td className='text-center'>{product.required}</td>
                                            <td className='text-center'>{product.storageUnitType}</td>
                                            <td className='text-center'>{product.categoryName}</td>
                                            <td className='text-center'>{product.categoryType ? product.categoryType : ''}</td>
                                            <td>
                                                <FontAwesomeIcon
                                                    className='genderIconAlignment'
                                                    color='white'
                                                    icon={faEdit}
                                                    data-toggle="tool-tip" title="Edit"
                                                    style={{ color: '#024a88', width: '15', cursor: 'pointer' }}
                                                    onClick={() => handleEdit(product, index, 'edit')}
                                                />
                                                <FontAwesomeIcon
                                                    className='genderIconAlignment'
                                                    color='white'
                                                    icon={faTrashAlt}
                                                    data-toggle="tooltip"
                                                    title="Delete"
                                                    style={{ color: '#bf1725', width: '13', marginLeft: 30, cursor: 'pointer' }}
                                                    onClick={() => {
                                                        setSelectedProduct(product);
                                                        setShowDeleteRowModal(true);
                                                    }}
                                                />
                                            </td>
                                        </tr>
                                    ))
                                )}
                            </tbody>

                        </table>
                    </div>

                    {/* Delete Row Modal */}
                    <DeleteRowModal
                        openDeleteModal={showDeleteRowModal}
                        closeDeleteModal={() => setShowDeleteRowModal(false)}
                        deleteSelectedRow={handleDeleteRowConfirmation}
                    />

                    {/* Add/Edit Modal */}
                    <Dialog
                        visible={showAddModal}
                        onHide={hideAddModal}
                        closeOnEscape={false}

                        header={
                            <div style={{ fontWeight: '300', fontSize: '16px' }}>
                                {isEditing ? "Edit Recommended Inventory" : "Add Recommended Inventory"}
                            </div>
                        }

                        draggable={false}
                        style={{ width: '50vw' }}
                    >
                        <div>
                            <div style={{ display: 'flex', flexDirection: 'row-reverse', marginTop: '10px' }}>
                                <button onClick={() => handleSubmit(onSubmit)()} className="btn custom-outline">
                                    {isEditing ? "Update" : "Save"}
                                </button>&nbsp;
                                <button className="btn custom-outline" onClick={hideAddModal} style={{ marginRight: '1rem' }}>Cancel</button>
                            </div>
                            <div className='form-fields-container'>
                                {formFields.map((item, index) => (
                                    <div className="col-11" key={index}>
                                        <div>
                                            {/* <label style={{ paddingLeft: '2rem' }}>{item.label}</label> */}
                                        </div>
                                        <div className="form__form-group">
                                            {item.type === 'autocomplete' && (
                                                <Controller
                                                    name={item.field}
                                                    control={control}
                                                    render={({ field, fieldState }) => (
                                                        <DoAutoCompleteField
                                                            markReq={item.required}
                                                            input={field}
                                                            id={field.id}
                                                            name={field.name}
                                                            field={field}
                                                            filterField={item.filterField}
                                                            filterValue={item.filterValue}
                                                            filterType={item.isNotEq}
                                                            multiple={item.isMultiple}
                                                            fieldState={fieldState}
                                                            errors={errors}
                                                            searchApi={item.searchApi}
                                                            searchField={item.searchField}
                                                            filterFieldType={item.filterFieldType ? item.filterFieldType : null}
                                                            placeholder={item.placeholder}
                                                            label={item.label}
                                                            onChange={handleProductChanges}
                                                            item={item}
                                                            getValues={getValues}
                                                        />
                                                    )}
                                                />
                                            )}
                                            {item.type === 'text' && (
                                                <Controller
                                                    name={item.field}
                                                    control={control}
                                                    render={({ field, fieldState }) => (
                                                        <DoInputField
                                                            markReq={item.required}
                                                            input={field}
                                                            id={field.id}
                                                            name={field.name}
                                                            label={item.label}
                                                            field={field}
                                                            type={item.type ? item.type : "text"}
                                                            fieldState={fieldState}
                                                            errors={errors}
                                                            isDisable={item.disable}
                                                            defVal={item.value}
                                                            placeholder={item.placeholder ? item.placeholder : null}
                                                        />
                                                    )}
                                                />
                                            )}
                                            {item.type === 'dropDown' && (
                                                <Controller
                                                    name={item.field}
                                                    control={control}
                                                    render={({ field, fieldState }) => (
                                                        <DoSelectField
                                                            input={field}
                                                            markReq={item.required}
                                                            id={field.id}
                                                            name={field.name}
                                                            field={field}
                                                            label={item.label}
                                                            fieldState={fieldState}
                                                            errors={errors}
                                                            options={item.options}
                                                            optionLabel={item.optionLabel ? item.optionLabel : 'label'}
                                                            placeholder={item.placeholder}
                                                        />
                                                    )}
                                                />
                                            )}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </Dialog>
                </div>
            </CardBody>
        </Card>
    );
}
