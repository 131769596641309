import React, { useEffect, useState } from 'react';
import DataTables from '../../CommonDataTable/DataTable';
import config from '../../../../config/config';
import apiCalls from '../../../../config/apiCalls'
import store from '../../../App/store';
import RolePermissions from '../../CommonModals/Permissions';
import ViewModal from '../../CommonModals/viewModal';
import { useParams } from 'react-router';
// config file
const Activities = (props) => {

	const [type, setType] = useState('Activities');
	const [paramsObj, setParamsObj] = useState();

	const [rolePermission, setRolePermission] = useState('Activities');
	const query = new URLSearchParams(location.search);
	const companyId = query.get('comapnyId');
	const params = useParams();
	const getDerivedStateFromProps = (props, state) => {
		let storeData = store.getState()
		let languageData = storeData && storeData.settingsData && storeData.settingsData.settings && storeData.settingsData.settings.languageTranslation ? storeData.settingsData.settings.languageTranslation : ""
		return { languageData: languageData };
	}



	useEffect(() => {
		let screenPermissions = RolePermissions.screenPermissions('Activities');
		if (screenPermissions) {
			setRolePermission(screenPermissions);
		} if (params.id && params.id.includes('-')) {
			let arr = params.id.split('-');
			setParamsObj({
				storageUnitName: arr[0],
			})
		} else {
			setParamsObj({})
		}

	}, [])

	const getTableFields = () => {
		let data = [
			{
				textAlign: "center",
				width: 47,
				field: "productName",
				header: "Product Name",
				label: "Product Name",
				filter: false,
				sortable: false,
				mobile: true,
				placeholder: "Search",
				show: true,
				displayInSettings: true,
			},
			{
				show: true,
				textAlign: "left",
				width: 80,
				mobile: true,
				field: "lotId",
				header: "Lot Id",
				filter: false,
				label: "Lot Id",
				sortable: true,
				textCapitalize: true,
				displayInSettings: true,
			},
			{
				show: true,
				textAlign: "left",
				width: 80,
				mobile: true,
				field: "context",
				header: "Context",
				filter: false,
				label: "Context",
				sortable: true,
				textCapitalize: true,
				displayInSettings: true,
			},
			{
				textAlign: "left",
				width: 80,
				field: "desc",
				mobile: true,
				label: "Context Type",
				header: "Context Type",
				filter: false,
				sortable: true,
				show: true,
				displayInSettings: true,
			},
			{
				textAlign: "center",
				show: true,
				mobile: true,
				width: 140,
				field: "storageUnitName",
				label: "Storage Unit",
				fieldType: "text",
				type: 'text',
				// dateFormat: config.dateDayMonthFormat,
				header: "Storage Name",
				// filter: true,
				sortable: true,
				displayInSettings: true,
			},
			{
				textAlign: "center",
				show: true,
				mobile: true,
				width: 140,
				field: "created",
				label: "Storage Unit",
				fieldType: "text",
				type: 'date',
				// dateFormat: config.dateDayMonthFormat,
				header: "Created Date",
				// filter: true,
				sortable: true,
				displayInSettings: true,
			},
			{
				textAlign: "left",
				width: 90,
				field: "email",
				mobile: true,
				label: "Email",
				header: "Email",
				filter: false,
				sortable: true,
				show: false,
				displayInSettings: true,
			},
			// {
			// 	textAlign: "left",
			// 	width: 80,
			// 	field: "ipAddress",
			// 	label: "Ip Address",
			// 	mobile: true,
			// 	header: 'Ip Address',
			// 	filter: false,
			// 	sortable: true,
			// 	show: true,
			// 	displayInSettings: true,
			// },
			{
				textAlign: "left",
				width: 90,
				field: "deviceType",
				label: "Device Type",
				mobile: true,
				header: 'Device Type',
				filter: false,
				sortable: true,
				show: false,
				displayInSettings: true,
			},
			{
				textAlign: "left",
				width: 90,
				field: "browserName",
				label: "Browser Name",
				mobile: true,
				header: 'Browser Name',
				filter: false,
				sortable: true,
				show: false,
				displayInSettings: true,
			},
			{
				textAlign: "left",
				width: 100,
				field: 'osName',
				label: "Os Name",
				mobile: true,
				header: 'Os Name',
				filter: false,
				sortable: true,
				show: false,
				displayInSettings: true,
				"type": "dropDown",
				"fieldType": "dropDown",
				"filterElement": [
					{
						"label": "Windows",
						"value": "Windows",
						"color": "primary"
					},
					{
						"label": "Linux",
						"value": "Linux",
						"color": "primary"
					}
				],
				"options": [
					{
						"label": "Windows",
						"value": "Windows",
						"color": "primary"
					},
					{
						"label": "Linux",
						"value": "Linux",
						"color": "primary"
					}
				]
			},
			{
				textAlign: "left",
				width: 100,
				field: 'osVersion',
				label: "Os Version",
				mobile: true,
				header: 'Os Version',
				filter: false,
				sortable: true,
				show: false,
				displayInSettings: true,
			},]

		return data;
	};




	const getFormFields = () => {
		return ([
			{
				'show': false,
				"value": "",
				"type": "text",
				"name": "firstName",
				"label": "First Name",
				"id": "firstName",
				"placeholder": "First Name",
				"required": true
			},


		]);
	}

	return (
		<span>
			{
			paramsObj&&
			<DataTables
				getTableFields={getTableFields}
				formFields={getFormFields}
				actionsTypes={[{
					'name': 'Delete',
					"options": [
						{ 'label': 'Delete', 'value': 'Delete', 'show': rolePermission && rolePermission == "Edit" ? true : false, "multiple": true, },
					]
				},
				]}
				exportRequried={false}
				printRequried={false}
				addRequried={false}
				editRequired={false}
				deleteRequired={false}
				viewRequired={false}
				refresh={true}
				settingsRequired={true}
				filterRequired={false}
				gridRequried={false}
				sample={false}
				getPagination={true}
				selectionMode={true}
				globalSearchFieldName='activity'
				globalSearch={"Context / Email / Description"}
				type="Activities"
				apiResponseKey={"activities"}
				apiUrl={"activities/track"}
				routeTo='activities'
				displayViewOfForm='screen'
				params={paramsObj}
				selectedId={params.id}
				companyId={companyId}
			/>
}
		</span>
	);

}

export default Activities;